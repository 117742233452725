// This file configures the initialization of Sentry on the server.
// The config you add here will be used whenever the server handles a request.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import defaultIgnoreSentry from './defaultIgnoreSentry';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 1.0,
    environment: process.env.NEXT_PUBLIC_ENV,
    ignoreErrors: [
        ...defaultIgnoreSentry.ignoreErrors,
        'User rejected the request.',
        'The operation was aborted.',
        'encountered an error while attempting to update latest block',
        'Callback was already called.',
        'No RPC Url available for chainId',
        'Non-Error promise rejection captured with keys',
        'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission',
        'JSON RPC response format is invalid',
        'Document is not focused',
        'Read permission denied',
        'out of memory',
        "Cannot assign to read only property 'ethereum' of object",
        'Syntax error',
        'getInitialProps missing data at path: /wp-plain.php',
        "undefined is not an object (evaluating 'p.manager')",
        'invalid address (argument="address", value',
        "Cannot read properties of undefined (reading 'slice')",
        'Permission denied to access property',
        'Cancel rendering route',
        'The user aborted a request',
        'User canceled',
        'Hydration failed',
        'There was an error while hydrating',
        'Missing or invalid topic field',
        'Fetch is aborted',
        'User denied message signature.',
        'cancelled',
        'User rejected the signature request',
    ],
    denyUrls: defaultIgnoreSentry.denyUrls,
});
