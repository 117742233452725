import {useEffect, useMemo} from 'react';
import {useRouter} from 'next/router';
import {useCookie} from 'react-use';

import {Routes} from '~constants/routes';

export function useOnboardingNextPage() {
    const router = useRouter();
    return useMemo(() => {
        const goTo = router.query.go ? `${router.query.go}` : '/';
        const allow = Object.values(Routes)
            .map((route) => route.replace(/\/\[\[?\.*\w+]]?/, ''))
            .filter((s) => s.startsWith('/'));
        if (allow.some((a) => goTo.startsWith(a))) {
            return goTo;
        }

        return '/';
    }, []);
}

export default function useOnboardingRedirect() {
    const router = useRouter();
    const [hadOnboarding, setHadOnboardingCookie] = useCookie('hadOnboarding');

    useEffect(() => {
        if (!hadOnboarding) {
            if (router.asPath.startsWith(Routes.Onboarding)) {
                setHadOnboardingCookie('true', {
                    expires: new Date(2032, 1, 1),
                    path: '/',
                });
            } else {
                router.replace(
                    `${Routes.Onboarding}?go=${encodeURIComponent(router.asPath)}`,
                );
            }
        }
    }, [router.pathname]);
}
