import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    svg {
        width: 24px;
        height: 24px;
    }
    h2 {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        margin: 0 0 0 12px;
        display: block;
        color: #ffffff;
    }

    p {
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
        margin: 0;
        color: #ffffff;
        opacity: 0.8;
        display: block;
        width: 100%;
        overflow: hidden;
        white-space: normal;
    }
`;
export const Row = styled.div`
    display: flex;
    margin-bottom: 12px;
`;
