import {
    BSC,
    Config,
    DAppProvider as DappProvider,
    Mainnet,
    MetamaskConnector,
} from '@usedapp/core';
import {WalletConnectV2Connector} from '@usedapp/wallet-connect-v2-connector';
import React from 'react';

import env from '~utils/env';

export const rpcConfig = {
    [BSC.chainId]: env('RPC_ADDRESS_BSC', ''),
    [Mainnet.chainId]: env('RPC_ADDRESS_MAINNET', ''),
};
const chains = [Mainnet, BSC];

const config: Config = {
    autoConnect: true,
    fastMulticallEncoding: true,
    noMetamaskDeactivate: true,
    pollingInterval: 20000,
    notifications: {
        expirationPeriod: 1000,
        checkInterval: 1000,
    },
    readOnlyChainId: Mainnet.chainId,
    readOnlyUrls: rpcConfig,
    connectors: {
        metamask: new MetamaskConnector(),
        walletConnect: new WalletConnectV2Connector({
            chains,
            projectId: env('NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID', ''),
            rpcMap: rpcConfig,
        }),
    },
};

const DAppProvider: React.FC<{children?: React.ReactNode}> = ({children}) => {
    return <DappProvider config={config}>{children}</DappProvider>;
};

export default DAppProvider;
