import React, {createContext, useContext, useMemo} from 'react';
import {useLocalStorage} from 'react-use';

import {AuthLoginResponse} from '~types/Auth';
import useOnboardingRedirect from '~organisms/Onboarding/Aniamtion/useCloseOnboarding';
import {FCC} from '~types/global';
import {parseJwtToken, tokenIsValid} from '~utils/jwt';

type AuthProps = {
    children?: React.ReactNode;
};

type AuthState = {
    authorization?: string;
    token?: AuthLoginResponse;
    setToken: (token?: AuthLoginResponse) => void;
    removeToken: () => void;
};

const AuthContext = createContext<AuthState>({
    authorization: undefined,
    token: undefined,
    setToken: () => {},
    removeToken: () => {},
});

export const AuthProvider: FCC<AuthProps> = ({children}) => {
    const [token, setToken, removeToken] = useLocalStorage<
        AuthLoginResponse | undefined
    >('token');

    useOnboardingRedirect();

    return (
        <AuthContext.Provider value={{token, setToken, removeToken}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useJwtToken = () => {
    const {token, setToken, removeToken} = useContext(AuthContext);
    return {token, setToken, removeToken};
};

export const useParsedJwtToken = () => {
    const {token} = useContext(AuthContext);
    return useMemo(() => {
        try {
            return parseJwtToken(token?.accessToken?.token || '');
        } catch (e) {
            return undefined;
        }
    }, [token]);
};

export const useJwtTokenStatus = () => {
    const {token} = useContext(AuthContext);
    return useMemo(
        () => ({
            refreshTokenValid: tokenIsValid(token?.refreshToken),
            accessTokenValid: tokenIsValid(token?.accessToken),
        }),
        [token],
    );
};
