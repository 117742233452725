import '~styles/globals.css';
import '~styles/variables.css';
import 'react-toastify/dist/ReactToastify.css';
import type {AppProps} from 'next/app';
import {Hydrate, QueryClient, QueryClientProvider} from 'react-query';
import {appWithTranslation} from 'next-i18next';
import {useState} from 'react';
import {ToastContainer} from 'react-toastify';

import {AuthProvider} from '~providers/AuthProvider';
import {PlotsProvider} from '~providers/PlotsProvider';
import DAppProvider from '~providers/DappProvidedr';
import showApiError from '~utils/errors';

function MyApp({Component, pageProps}: AppProps) {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        onError: showApiError,
                        refetchOnWindowFocus: false,
                        refetchOnMount: false,
                    },
                },
            }),
    );

    return (
        <AuthProvider>
            <DAppProvider>
                <QueryClientProvider client={queryClient}>
                    <Hydrate state={pageProps.dehydratedState}>
                        <PlotsProvider>
                            <Component {...pageProps} />
                        </PlotsProvider>
                    </Hydrate>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="dark"
                    />
                </QueryClientProvider>
            </DAppProvider>
        </AuthProvider>
    );
}

export default appWithTranslation(MyApp);
