export enum Routes {
    Plot = '/plots/[id]',
    Index = '/[[...slug]]',
    MyProfile = '/profile',
    MyPlots = '/profile/plots/[[...slug]]',
    Onboarding = '/onboarding',
    LandSaleInfo = '/land-sale-info',
    Cashback = '/cashback',
    List = '/list',
}

export enum AdminRoutes {
    Home = '/admin',
    Reservation = '/admin/reservation',
    Tickets = '/admin/tickets',
    Plots = '/admin/plots',
    Avatars = '/admin/avatars',
}

export enum ScreenTypes {
    Map = '',
    List = 'list',
}
