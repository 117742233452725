const envs = {
    API: process.env.NEXT_PUBLIC_API,
    IDM_API: process.env.NEXT_PUBLIC_IDM_API,
    MAPBOX_ACCESS_TOKEN: process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN,
    MAPBOX_STYLES: process.env.NEXT_PUBLIC_MAPBOX_STYLES,
    DOME_ADDRESS: process.env.NEXT_PUBLIC_DOME_ADDRESS,
    NETWORK: process.env.NEXT_PUBLIC_NETWORK,
    HOST: process.env.NEXT_PUBLIC_HOST,
    RPC_ADDRESS_GOERLI: process.env.NEXT_PUBLIC_RPC_ADDRESS_GOERLI,
    RPC_ADDRESS_BSC: process.env.NEXT_PUBLIC_RPC_ADDRESS_BSC,
    RPC_ADDRESS_MAINNET: process.env.NEXT_PUBLIC_RPC_ADDRESS_MAINNET,
    CASHBACK_ADDRESS: process.env.NEXT_PUBLIC_CASHBACK_ADDRESS,
    CASHBACK_NETWORK: process.env.NEXT_PUBLIC_CASHBACK_NETWORK,
    MINT_PLOT_NETWORK: process.env.NEXT_PUBLIC_MINT_PLOT_NETWORK,
    MINT_PLOT_ADDRESS: process.env.NEXT_PUBLIC_MINT_PLOT_ADDRESS,
    PLOT_NFT_ADDRESS: process.env.NEXT_PUBLIC_PLOT_NFT_ADDRESS,
    PLOTS_OPENSEA: process.env.NEXT_PUBLIC_PLOTS_OPENSEA,
    NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID:
        process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,
};
const env = <T = undefined>(
    key: keyof typeof envs,
    defaultValue?: T,
): string | T => {
    // @ts-ignore
    return envs[key] || defaultValue;
};

export default env;
