import {toast} from 'react-toastify';
import {ToastOptions} from 'react-toastify/dist/types';

import ToastContainer from '~components/molecules/ToastContainer';
import SuccessIcon from '~assets/images/icons/ui/success-icon.svg';
import ErrorIcon from '~assets/images/icons/ui/error-icon.svg';

const options: ToastOptions = {
    position: 'top-center',
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    closeButton: <></>,
    icon: false,
};

export function showError(message: string, title?: string, id?: string) {
    toast.error(
        <ToastContainer
            icon={<ErrorIcon />}
            title={title || 'Error'}
            message={message}
        />,
        {
            ...options,
            ...(id && {toastId: id}),
        },
    );
}

export function showSuccess(message: string, title?: string, id?: string) {
    toast.success(
        <ToastContainer
            icon={<SuccessIcon />}
            title={title || 'Success'}
            message={message}
        />,
        {
            ...options,
            ...(id && {toastId: id}),
        },
    );
}
