import {Container, Row} from './styles';

type ToastContainerProps = {
    title: string;
    message: string;
    icon?: React.ReactNode;
};

const ToastContainer = ({title, message, icon}: ToastContainerProps) => {
    return (
        <Container>
            <Row>
                {icon}
                <h2>{title}</h2>
            </Row>
            <p>{message}</p>
        </Container>
    );
};

export default ToastContainer;
