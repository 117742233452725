import React, {createContext, useContext, useState} from 'react';

import {PlotsImagesListResponse, PlotsReservationsResponse} from '~types/Plots';

type PlotsProps = {
    imagePlots: PlotsImagesListResponse;
    premiumPlots: number[];
    reservedPlots: PlotsReservationsResponse;
    setImagePlots: (_imagePlots: PlotsImagesListResponse) => void;
    setPremiumPlots: (_premiumPlots: number[]) => void;
    setReservedPlots: (_reservedPlots: PlotsReservationsResponse) => void;
};
const initialPlotsState: PlotsProps = {
    premiumPlots: [],
    reservedPlots: [],
    imagePlots: [],
    setImagePlots: () => {},
    setPremiumPlots: () => {},
    setReservedPlots: () => {},
};

const PlotsContext = createContext<PlotsProps>(initialPlotsState);

export const PlotsProvider: React.FC<{children?: React.ReactNode}> = ({
    children,
}) => {
    const [premiumPlots, setPremiumPlots] = useState(initialPlotsState.premiumPlots);
    const [imagePlots, setImagePlots] = useState(initialPlotsState.imagePlots);
    const [reservedPlots, setReservedPlots] = useState(
        initialPlotsState.reservedPlots,
    );

    return (
        <PlotsContext.Provider
            value={{
                premiumPlots,
                setPremiumPlots,
                reservedPlots,
                setReservedPlots,
                imagePlots,
                setImagePlots,
            }}
        >
            {children}
        </PlotsContext.Provider>
    );
};

export const usePlots = () => useContext(PlotsContext);
