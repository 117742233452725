import {parseApiError} from '@uigstudio/uig-nextjs-helper';

import {ApiErrorType} from '~types/Api';
import {showError} from '~utils/toast';

const errorBlockList = [/TokenExpiredError/];

export default function showApiError(e: unknown) {
    const errorMessage = parseApiError(e as ApiErrorType).message;
    if (!errorBlockList.some((errorBlock) => errorBlock.test(errorMessage))) {
        showError(errorMessage);
    }
}
